<script>
    import { onMount } from "svelte";
    import { slide, fade } from "svelte/transition";
    import { cubicInOut } from "svelte/easing";

    const csrfToken = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content");

    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.has("lang")
        ? urlParams.get("lang")
        : navigator.language || navigator.userLanguage;
    if (!["de", "fr", "it", "en"].includes(lang)) {
        window.location.replace("/?lang=en");
    }

    let question;

    let frontendFields;
    let messages = [];
    let firstUserMsg = 0;
    let topicBegin = 0;
    let sources = [];
    let sourcesRaw = [];
    let cost = 0;
    let db_id = -1;
    let questionPending = false;
    let personalData = [];
    let flag = -1;

    const markdownToHTML = new showdown.Converter();

    const infoDialogFields = ["about", "datenschutz", "haftung", "impressum"];
    let infoDialogContent = {};
    let showInfoDialogSelect = false;
    let selectedInfoDialog = "";

    let lastMsgDiv;
    let footnoteHeader;

    const urlPattern = /https:\/\/[A-Za-z0-9\.\-\?\!\=\&\_\/]+/g;
    const emailPattern =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g; // https://emailregex.com/
    const footnotePattern = /<fn>S(\d+)<\/fn>/g;

    function getMsgHTML(text, msgIndex) {
        let msgHTML = markdownToHTML.makeHtml(text);
        const urls = [...new Set(msgHTML.match(urlPattern))];
        for (let i = 0; i < urls.length; i++) {
            msgHTML = msgHTML.replaceAll(
                urls[i],
                `<a href="${urls[i]}" target="_blank" class="underline
					cursor-pointer color-accent" style="color: #DC0018;">${urls[i]}</a>`,
            );
        }
        const emails = [...new Set(msgHTML.match(emailPattern))];
        for (let i = 0; i < emails.length; i++) {
            msgHTML = msgHTML.replaceAll(
                emails[i],
                `<a href="mailto:${emails[i]}" class="underline cursor-pointer">${emails[i]}</a>`,
            );
        }

        let fnMatch;
        let fnCount = 1;
        do {
            fnMatch = footnotePattern.exec(msgHTML);
            if (fnMatch) {
                let sourceIndex = parseInt(fnMatch[1]) - 1;
                msgHTML = msgHTML.replace(
                    fnMatch[0],
                    `<button
					class="rounded-full px-2 ml-2 border border-base bg-accent text-textsec
					hover:bg-base hover:text-textcol cursor-pointer"
					onclick="setMsgFootnote(${msgIndex}, ${sourceIndex})"">
						${fnCount}
					</button>`,
                );
                fnCount++;
            }
        } while (fnMatch);
        return msgHTML;
    }

    function setMsgFootnote(msgIndex, sourceIndex) {
        for (let i = 0; i < messages.length; i++) {
            messages[i].openFootnote = -1;
        }
        messages[msgIndex].openFootnote = sourceIndex;
        messages = [...messages];
    }
    window.setMsgFootnote = setMsgFootnote;

    function closeFootnote(msgIndex) {
        messages[msgIndex].openFootnote = -1;
        messages = [...messages];
    }

    function setupConversation() {
        firstUserMsg = 0;
        messages = [
            ...frontendFields.greeting_msg.split("\n").map((msg) => {
                firstUserMsg++;
                return {
                    role: "assistant",
                    content: msg,
                    html: getMsgHTML(msg, 0),
                };
            }),
        ];
        sources = [];
        sourcesRaw = [];
        cost = 0;
        db_id = -1;
        flag = -1;
    }

    function setupInfoDialogs() {
        for (let i = 0; i < infoDialogFields.length; i++) {
            const lineBreakIndex =
                frontendFields[infoDialogFields[i]].indexOf("\n");
            const infoHeader = getMsgHTML(
                frontendFields[infoDialogFields[i]].substring(
                    0,
                    lineBreakIndex,
                ),
                0,
            );
            const infoHTML = getMsgHTML(
                frontendFields[infoDialogFields[i]].substring(
                    lineBreakIndex + 1,
                ),
                0,
            );
            infoDialogContent[infoDialogFields[i]] = {
                header: infoHeader,
                html: infoHTML,
            };
        }
        selectedInfoDialog = infoDialogFields[0];
    }

    function initFrontendFields(j) {
        frontendFields = j;
        frontendFields.example_questions =
            frontendFields.example_questions.split("\n");
        setupConversation();
        setupInfoDialogs();
    }

    function handleGPTAnswer(answer) {
        cost = answer.cost;
        topicBegin = answer.topic_begin;
        db_id = answer.db_id;
        sourcesRaw = answer.sources;
        sources = answer.sources.map((s) => {
            return {
                text: s[1],
                metadata: JSON.parse(s[2]),
            };
        });
        questionPending = false;
        flag = -1;
        messages = [
            ...messages,
            {
                role: "assistant",
                content: answer.answer,
                html: getMsgHTML(answer.answer, messages.length),
                sources: sources,
            },
        ];
    }

    $: {
        if (lastMsgDiv && !footnoteHeader) {
            lastMsgDiv.parentElement.scrollIntoView();
        }
    }
    $: {
        if (footnoteHeader) {
            footnoteHeader.scrollIntoView();
        }
    }

    function sendQuestionToLLM() {
        fetch("/api/chat", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
            },
            body: JSON.stringify({
                messages: messages.slice(firstUserMsg),
                sources: sourcesRaw,
                cost: cost,
                topic_begin: topicBegin,
                db_id: db_id,
            }),
        })
            .then((res) => res.json())
            .then((j) => handleGPTAnswer(j));
    }

    function rateConversation(rating) {
        fetch("/api/flag", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
            },
            body: JSON.stringify({
                db_id: db_id,
                flag: rating,
                last_msg: messages[messages.length - 1].content,
            }),
        })
            .then((res) => res.json())
            .then((j) => {
                if (j.success) {
                    flag = rating;
                } else {
                    flag = -2;
                }
            });
    }

    function handlePDResult(pd) {
        if (pd.length === 0) {
            sendQuestionToLLM();
        } else {
            personalData = pd;
        }
    }

    function handlePDClick(proceed) {
        personalData = [];
        if (proceed) {
            sendQuestionToLLM();
        } else {
            messages = [...messages.slice(0, -1)];
            questionPending = false;
        }
    }

    function askQuestion() {
        questionPending = true;
        document.getElementById("questionfield").blur();
        for (let i = 0; i < messages.length; i++) {
            messages[i].openFootnote = -1;
        }
        messages = [
            ...messages,
            {
                role: "user",
                content: question,
                html: getMsgHTML(question, 0),
            },
        ];
        fetch(
            "/api/testpd?" +
                new URLSearchParams({
                    query: question,
                }),
        )
            .then((res) => res.json())
            .then((j) => handlePDResult(j));
        question = "";
    }

    function openInfoDialog(field) {
        selectedInfoDialog = field;
        document.getElementById("infoDialog").showModal();
        document.getElementById("dialogHeader").scrollIntoView();
        showInfoDialogSelect = false;
    }

    onMount(() => {
        fetch(
            "/api/frontendfields?" +
                new URLSearchParams({
                    fields: "[]",
                    lang: lang,
                }),
        )
            .then((res) => res.json())
            .then((j) => initFrontendFields(j));
    });
</script>

<main
    class="w-screen min-h-screen h-screen p-0 bg-base flex flex-col items-center"
>
    <div
        class="h-18 w-full bg-base flex flex-row px-4 items-center justify-between"
    >
        <p></p>
        <img
            style="height: 15; width: 15vh;"
            alt="Frag den Onlinekommentar"
            src="/static/frontend/chatbot/logo-no-border.svg"
        />
        <div class="flex flex-row items-center">
            <select
                class="bg-accent p-2 text-textsec rounded-md cursor-pointer hover:bg-base
                    hover:text-textcol"
                on:change={(e) =>
                    window.location.replace(`/?lang=${e.target.value}`)}
            >
                <option value="de" selected={lang === "de"}>Deutsch</option>
                <option value="fr" selected={lang === "fr"}>Français</option>
                <option value="it" selected={lang === "it"}>Italiano</option>
                <option value="en" selected={lang === "en"}>English</option>
            </select>
            <button
                class="text-accent ml-2 p-2 border-none rounded-full cursor-pointer hover:bg-accent
                    hover:text-textsec flex items-center justify-center w-10 h-10"
                on:click={() => (showInfoDialogSelect = !showInfoDialogSelect)}
            >
                <i class="fa-solid fa-circle-info fa-xl"></i>
            </button>
        </div>
    </div>
    {#if showInfoDialogSelect}
        <div
            class="w-full flex flex-col bg-accent"
            transition:slide={{ duration: 1000 }}
        >
            {#each infoDialogFields as field}
                <button
                    class="w-full bg-accent text-textsec hover:bg-base
				hover:text-textcol text-lg cursor-pointer border-accent flex
				items-center justify-center p-2"
                    on:click={() => openInfoDialog(field)}
                >
                    {@html infoDialogContent[field].header}
                </button>
            {/each}
        </div>
    {/if}
    <div
        class="flex-grow w-full md:landscape:w-2/3 flex
            flex-col overflow-y-auto p-4"
        style="min-height: 0px;"
        id="conversation"
    >
        {#each messages as msg, i}
            <div
                class="chat"
                class:chat-start={msg.role === "assistant"}
                class:chat-end={msg.role === "user"}
                transition:fade={{
                    delay: 50,
                    duration: 300,
                    easing: cubicInOut,
                }}
            >
                <div
                    class="chat-bubble bg-accent text-textsec text-justify break-words"
                >
                    {#if i === messages.length - 1}
                        <div bind:this={lastMsgDiv}>
                            {@html msg.html}
                        </div>
                    {:else}
                        {@html msg.html}
                    {/if}
                </div>
            </div>
            {#if (msg.openFootnote === 0 || msg.openFootnote) && msg.openFootnote >= 0 && msg.openFootnote < msg.sources.length}
                <div
                    class="flex flex-col mt-4 border border-accent rounded-md"
                    bind:this={footnoteHeader}
                    transition:fade={{
                        delay: 50,
                        duration: 300,
                        easing: cubicInOut,
                    }}
                >
                    <div
                        class="flex flex-row items-center justify-between w-full
                            p-2 bg-accent"
                    >
                        {#if msg.sources[msg.openFootnote].metadata.url}
                            <p class="text-textsec flex flex-row">
                                <a
                                    href={msg.sources[msg.openFootnote].metadata
                                        .url}
                                    class="underline w-full md:landscape:w-auto"
                                    style="color: white;"
                                    target="_blank"
                                    >{frontendFields.to_source_msg}</a
                                >
                            </p>
                        {:else}
                            <p></p>
                        {/if}
                        <button
                            class="text-textcol border-none rounded-full p-2 cursor-pointer
    						h-8 w-8 flex items-center justify-center text-textsec"
                            on:click={() => closeFootnote(i)}
                        >
                            <i class="fa-solid fa-x"></i>
                        </button>
                    </div>
                    <h3 class="font-bold text-textcol mt-2 p-2">
                        {msg.sources[msg.openFootnote].metadata.header}
                    </h3>

                    <p class="text-textcol p-2">
                        {msg.sources[msg.openFootnote].text}
                    </p>
                </div>
            {/if}
        {/each}
        {#if personalData.length > 0}
            <div
                class="flex flex-col border border-textcol p-4"
                transition:fade={{
                    delay: 50,
                    duration: 300,
                    easing: cubicInOut,
                }}
            >
                <p class="text-textcol">
                    {frontendFields.pd_message}
                </p>
                {#each personalData as pd}
                    <p class="text-texcol">{pd.text} ({pd.type})</p>
                {/each}
                <div class="flex flex-row">
                    <button
                        class="flex-grow h-12 btn border-accent bg-accent text-textsec rounded-none
						hover:bg-textcol hover:text-textsec"
                        on:click={() => handlePDClick(false)}
                    >
                        {frontendFields.abort_text}
                    </button>
                    <button
                        class="flex-grow h-12 btn border-accent bg-base text-textcol rounded-none
						hover:bg-textcol hover:text-textsec"
                        on:click={() => handlePDClick(true)}
                    >
                        {frontendFields.proceed_text}
                    </button>
                </div>
            </div>
        {/if}
        {#if questionPending && personalData.length === 0}
            <div class="chat chat-start">
                <div class="chat-bubble bg-accent text-textsec">
                    <span class="loading loading-dots loading-md text-textsec"
                    ></span>
                </div>
            </div>
        {/if}
    </div>
    <div class="flex flex-col w-full md:landscape:w-2/3 p-4">
        {#if messages.length > firstUserMsg && messages[messages.length - 1].role === "assistant"}
            {#if flag === -1}
                <p class="text-textcol">
                    {frontendFields.rate_msg}
                </p>
                <div class="w-full flex flex-row">
                    <button
                        class="flex-grow h-12 rounded-l-lg rounded-r-none bg-base border-accent text-textcol
					hover:bg-accent hover:text-textsec cursor-pointer"
                        on:click={() => rateConversation(1)}
                    >
                        <i class="fa-solid fa-thumbs-up"></i>
                    </button>
                    <button
                        class="flex-grow h-12 rounded-r-lg rounded-l-none bg-base border-accent text-textcol
					hover:bg-accent hover:text-textsec cursor-pointer"
                        on:click={() => rateConversation(0)}
                    >
                        <i class="fa-solid fa-thumbs-down"></i>
                    </button>
                </div>
            {:else if flag === 0}
                <p class="text-textcol">
                    {frontendFields.negative_feedback_msg}
                </p>
                <div
                    class="w-full h-12 bg-base border border-accent rounded-full text-textcol hover:bg-accent
					hover:text-textsec cursor-pointer flex flex-row items-center p-2"
                >
                    <a
                        class="w-full h-full flex flex-row items-center justify-center"
                        href={`mailto:${frontendFields.feedback_email}?subject=${encodeURIComponent(
                            frontendFields.email_subject,
                        )}&body=${encodeURIComponent(
                            frontendFields.email_template.replaceAll(
                                "#MESSAGES#",
                                messages
                                    .slice(firstUserMsg)
                                    .map((m) => m.content)
                                    .join("\n\n"),
                            ),
                        )}`}
                    >
                        <i class="fa-solid fa-envelope"></i>
                        <p class="ml-2">{frontendFields.feedback_email}</p>
                    </a>
                </div>
            {:else if flag === 1}
                <p class="text-textcol">
                    {frontendFields.positive_feedback_msg}
                </p>
            {:else if flag === -2}
                <p class="text-textcol">
                    {frontendFields.feedback_error_msg}
                </p>
            {/if}
        {/if}
        {#if messages.length > 0 && messages.length <= firstUserMsg}
            <div class="w-full flex flex-col">
                {#each frontendFields.example_questions as eq, i}
                    <button
                        class="flex-grow w-full bg-accent border-accent
					text-textsec p-2 cursor-pointer hover:bg-base hover:text-textcol
					rounded-none"
                        class:rounded-t-lg={i === 0}
                        class:rounded-b-lg={i ===
                            frontendFields.example_questions.length - 1}
                        on:click={() => {
                            question = eq;
                            askQuestion();
                        }}
                    >
                        {eq}
                    </button>
                {/each}
            </div>
        {/if}
        <div
            class="w-full mt-8 flex flex-row items-center rounded-full outline-accent outline-1 hover:outline"
        >
            <input
                id="questionfield"
                class="p-2 h-12 rounded-l-full rounded-r-none flex-grow bg-textsec border border-r-0 border-textcol
			text-textcol placeholder-textcol focus:outline-accent"
                placeholder={frontendFields
                    ? frontendFields.question_placeholder
                    : ""}
                on:keydown={(e) => e.key === "Enter" && askQuestion()}
                bind:value={question}
            />
            <button
                class="btn p-2 rounded-r-full border border-l-0 border-textcol rounded-l-none bg-accent text-textsec h-12 w-12 border-accent
			hover:bg-base hover:text-textcol"
                on:click={askQuestion}
            >
                <i class="fa-solid fa-circle-arrow-right"></i>
            </button>
        </div>
    </div>
    {#if frontendFields}
        <p class="text-textcol text-sm p-2 hidden md:landscape:block">
            {frontendFields.bottom_disclaimer}
        </p>
    {/if}
</main>

{#if selectedInfoDialog}
    <dialog id="infoDialog" class="modal glass w-screen h-screen rounded-none">
        <div
            class="modal-box text-textcol bg-base prose prose-xs
			prose-headings:text-textcol prose-a:text-accent
			prose-a:color-accent prose-strong:text-textcol
			prose-li:color-accent"
        >
            <h3 class="font-bold text-lg" id="dialogHeader">
                {@html infoDialogContent[selectedInfoDialog].header}
            </h3>
            <div
                class="h-5/6 overflow-y-auto flex flex-col justify-start bg-base"
            >
                {@html infoDialogContent[selectedInfoDialog].html}
            </div>

            <div class="modal-action">
                <form method="dialog">
                    <button
                        class="btn bg-accent outline-accent
					focus:border-accent border-accent
					text-textsec hover:bg-base hover:text-textcol">Ok</button
                    >
                </form>
            </div>
        </div>
    </dialog>
{/if}
